
import { Component, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/enums/routes/RouteNames';
import moment from 'moment';
import Resource from '@/models/Resource';
import ResourceRepository from '@/repositories/ResourceRepository';
import i18n from '@/i18n';

@Component({
    name: 'CalendarResources',
    components: {},
})
export default class CalendarResources extends Vue {
    private moment = moment;
    private loading: boolean = false;

    private get resources() {
        return ResourceRepository.getAll();
    }
    private fields = [
        {
            title: i18n.t('Naziv'),
            key: 'title',
            dataIndex: 'title',
        },
        {
            title: i18n.t('Opis'),
            key: 'description',
            dataIndex: 'description',
        },
        {
            title: i18n.t('Datum'),
            key: 'dateInserted',
            dataIndex: 'dateInserted',
            scopedSlots: {
                customRender: 'date',
            },
        },
        {
            title: i18n.t('Akcije'),
            key: 'actions',
            dataIndex: 'actions',
            scopedSlots: {
                customRender: 'actions',
            },
        },
    ];
    private async retrieveResources() {
        this.loading = true;
        try {
            await Resource.getAll();
            this.loading = false;
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
            this.loading = false;
            return Promise.reject();
        }
    }

    private editItem(itemId: string) {
        this.$router.push({
            name: RouteNames.schedulesResources,
            params: { id: itemId.toString(), dynamic: 'CalendarResourceEdit' },
        });
    }

    private addResource() {
        this.$router.push({
            name: RouteNames.schedulesResources,
            params: { dynamic: 'CalendarResourceEdit' },
        });
    }
    private async deleteResource(id: string) {
        this.loading = true;
        await Resource.deleteExisting(id);
        await this.retrieveResources();
    }
    private async mounted() {
        await this.retrieveResources();
    }
}
